.button {
  @apply .uppercase .inline-flex .items-center .justify-center;
  padding: calc-rem(14) 2.5rem;
  color: $color__white;
  font-size: 1rem;
  font-family: $font-title;
  background-color: $color__black;
  transition: .4s color $easing__material, .4s background-color $easing__material, .4s opacity $easing__material, .4s border $easing__material;

  .icon {
    fill: $color__white;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $color__dark-grey;
  }

  &--ghost {
    border: 1px solid $color__black;
    border-left: none;

    &:hover {
      border-color: transparent;
    }
  }

  &--white {
    color: $color__black;
    background-color: $color__white;

    .icon {
      fill: $color__black;
    }

    &:hover {
      color: $color__white;

      svg {
        fill: $color__white;
      }
    }
  }

  &--tunnel {
    @apply .absolute;
    bottom: 0;
    z-index: 15;
  }

  &.is-disabled {
    opacity: .5;
    pointer-events: none;
  }

  &--next {
    right: 0;
  }

  &--prev {
    left: 0;
  }

  svg,
  span {
    pointer-events: none;
  }
}

.button-wrapper:hover .button {
  background-color: $color__dark-grey;
}

.link-underline {
  @apply .relative;
  font-size: calc-rem(14);

  &--small {
    font-size: .75rem;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid;
    transform-origin: 0% 0%;
  }

  &--modify {
    @apply .uppercase;
    color: $color__white;
    font-size: .75rem;
    font-family: $font-title;
  }

  &:hover:after {
    animation: drawLine .5s $easing__material;
  }

  @keyframes drawLine {
    0% {
      transform: scaleX(0);
    }

    100% {
      transform: scaleX(1);
    }
  }
}

.link-preview {
  @apply .flex .items-center .absolute .uppercase;
  top: 1rem;
  left: 1rem;
  z-index: 5;
  font-family: $font-title;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 2px;
    left: 2rem;
    border-bottom: 1px solid;
    transform: scaleX(0);
    transform-origin: 0% 0%;
    transition: transform .5s $easing__material;
  }

  &:hover:after {
    transform: scaleX(1);
  }
}