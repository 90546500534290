// titles
.title-1 {
  @apply .uppercase;
  font-size: 3rem;
  font-family: $font-title;
  letter-spacing: 2px;

  &--no-spacing {
    letter-spacing: 0;
  }
}

.title {
  font-family: $font-title;
}

.title-2 {
  @apply .uppercase;
  font-size: calc-rem(18);
}

.title-3 {
  font-size: 3rem;
  letter-spacing: -1.5px;

  &--small {
    font-size: 2.5rem;
  }
}

.chapo {
  font-size: 2rem;
  font-family: $font-chapo;
  line-height:1.25;

  &--big {
    font-size: 3rem;
  }
}

.paragraph {
  color: $color__dark-grey;
  font-size: 1rem;

  &--big {
    font-size: calc-rem(20);
    line-height: 1.8;
  }
}

.text-underline {
  @apply .inline-block;
  color: $color__black;
  font-weight: 600;
  line-height: .85;
  border-bottom: 2px solid;
}
