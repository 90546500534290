$color__white: #fff;
$color__black: #000;
$color__grey: #777777;
$color__bg-grey: #d9d9d9;
$color__dark-grey: #5c5c5c;
$color__light-grey: #dedede;
$color__red: #cc0000;
$color__gold: #b39d82;

$colors: ( 
  'white': $color__white,
  'black': $color__black,
  'grey': $color__grey,
  'red': $color__red,
)