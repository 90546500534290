.header {
  @apply .flex .justify-center .items-center .relative;
  z-index: 10;
  height: 5rem;
  border-bottom: 1px solid $color__light-grey;

  &__logo {
    width: calc-rem(144);
  }

  &__connected {
    @apply .absolute .flex .items-center;
    top: 0;
    right: 2rem;
    height: 5rem;
  }

  &__logout {
    @apply .flex .items-center .uppercase .relative;
    font-family: $font-title;


    &:before {
      content: '';
      position: absolute;
      right: 1.75rem;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid;
      transform: scaleX(0);
      transform-origin: 0% 0%;
      transition: .5s $easing__material transform;
    }

    &:hover:before {
      transform: scaleX(1);
    }
  }

  &__user {
    @apply .relative;
    margin-right: 4rem;
    color: $color__grey;
    font-size: calc-rem(18);
    font-family: $font-chapo;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: -2rem;
      height: 2rem;
      border-right: 1px solid $color__light-grey;
      transform: translateY(-50%);
    }
  }
}