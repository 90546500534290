html, body {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: $font-body;
  line-height: normal;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.preload * {
  transition: none!important;
}