@tailwind utilities;
@tailwind base;

@import 'tools';

// BASE
@import 'base/typography';
@import 'base/core';
@import 'base/common';
@import 'utils/resets';

// SHARED COMPONENTS
@import 'components/buttons';
@import 'components/header';
@import 'components/footer';
