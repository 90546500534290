.sr-only {
  @include sr-only();
}

// avoid broken image symbol
img.lazyload:not([src]) {
  visibility: hidden;
}

.no-scroll-y {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.icon {
  @each $name, $color in $colors {
    &#{&}--#{$name} {
      fill: $color;
    }
  }

  &--reversed {
    transform: rotate(-180deg);
  }

  &--rotated {
    transform: rotate(-90deg);
  }
}

.app-body {
  @apply .flex;
  min-height: calc(100vh - 5rem);
}

// use it with js-full-height-mobile
// useful for calculations w/ IOS bottom bar
.full-height-mobile {
  @include max-responsive(md) {
    min-height: 100vh; 
    min-height: calc(var(--vh, 1vh) * 100);
  }
}

a {
  transition: .4s $easing__material color;
}

svg {
  transition: .4s $easing__material fill;
}

.sf-minitoolbar {
  z-index: 10;
}

.object-fit-image-wrap {
  @apply .absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.object-fit-image {
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  /* stylelint-disable-next-line */
  font-family: 'object-fit: cover;'; // Polyfill
}

.no-wrap {
  white-space: nowrap;
}

.is-hidden {
  display: none!important;
}